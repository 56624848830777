import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { navigate } from "gatsby"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"

function Contact() {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const submitContactForm = event => {
    event.preventDefault()
    const type = "Contact"
    const url =
      "https://3o10wpxx9l.execute-api.us-west-2.amazonaws.com/default/isn-www-signup?type=" +
      type +
      "&name=" +
      name +
      "&email=" +
      email +
      "&phone=" +
      phone +
      "&message=" +
      message
    let encodedurl = encodeURI(url)
    fetch(encodedurl).then(navigate("/thankyou/"))
  }

  return (
    <div>
      <Helmet
        title="Contact Us - Inspection Support Network"
        defer={false}
      />

      <SEO 
        title="Contact Us - Inspection Support Network" 
        description="Choose the most convenient method to get in touch with our home inspection software experts today!" 
      />

      <Container>
        <Header activepage="| Contact" />
      </Container>
      <Container className={'legacy-page-spacer'} fluid>
        <Row
          style={{
            height: "250px",
            backgroundImage:
              'url("https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/cell-phone-in-hand-blurred-1030x425.jpg")',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Col sm={12} lg={12}>
            <div
              style={{ position: "relative", width: "100%", marginTop: "50px" }}
            >
              <div className="text-center align-middle">
                <img
                  className="contact-icon"
                  style={{ padding: "10px",maxWidth:"110px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/contact-icon-phone.png"
                  alt="phone"
                />
                <img
                  className="contact-icon"
                  style={{ padding: "10px",maxWidth:"110px" }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/contact-icon-envelope.png"
                  alt="envelope"
                />
                <img
                  className="contact-icon"
                  style={{ padding: "10px",maxWidth:"110px"  }}
                  src="https://s3-us-west-2.amazonaws.com/www.inspectionsupport.net-images/images/contact-icon-chat.png"
                  alt="chat"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col sm={4}>
            <br/>
            <h3 style={{ fontSize: "20px" }}>Live Chat</h3>
            <h4 style={{ color: "#2e75bc" }}>
              <a
                style={{ color: "#2e75bc" }}
                href="https://help.inspectionsupport.com/en/"
              >
                Chat with Us
              </a>
            </h4>
            <p>Available Monday – Friday 5AM PST to 5PM PST (GMT-5)</p>
          </Col>
          <Col sm={4}>
          <br/>

            <h3 style={{ fontSize: "20px" }}>Toll Free Phone</h3>
            <h4 style={{ color: "#2e75bc" }}>800-700-8112</h4>
            <p>Available Monday – Friday 6AM PST to 5PM PST (GMT-5)</p>
          </Col>
          <Col sm={4}>
          <br/>

            <h3 style={{ fontSize: "20px" }}>Email Us</h3>
            <h4>
              <a
                href="mailto:help@inspectionsupport.com"
                style={{ color: "#2e75bc" }}
              >
                help@inspectionsupport.com
              </a>
            </h4>
          </Col>
        </Row>
        <Row style={{ padding: "10px 0px" }}>
          <Col sm={12}>
            <hr />
            <h1 className="bold">Send us an Email now</h1>
            <br />
            <Form onSubmit={submitContactForm}>
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="phone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="phone"
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="message">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                />
              </Form.Group>
              <br />
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  )
}

export default Contact
